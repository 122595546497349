/*@import url('https://fonts.googleapis.com/css?family=El+Messiri&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');*/

@font-face {
  font-family: 'Lato';
  src: url('./fonts/Lato-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Bebas';
  src: url('./fonts/BebasNeue-Regular.ttf') format('truetype');
}


svg{
  width: auto !important;
}

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  color: white;
  background: white;
}

textarea {
  font-family: 'Lato', sans-serif;
}

.app-content{
  margin-left: 150px;
}

.main-row{
  background: rgb(255,255,255, 0.4);
  position: absolute;
  top: 0;
  right: 0;
  height: 500px;
  width: 50%;
  z-index: 99;
}

#main-info{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

#main-img{
  width: auto;
  height: 50%;
  display: flex;
  animation: scale-in-center 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.info-section{
  background: linear-gradient(to left, #000428, #004592);
  color: white;
}

.info-section span{
  text-align: justify;
  font-size: 1.5em;
  padding: 0 20px;
}

.who{
  display: flex;
  flex-direction: row;
  width: 100%;
}

#img-who{
  display: flex;
  width: 20%;
}

#text-who{
  display: flex;
  text-align: justify;
  width: 80%;
  align-items: center;
  padding: 30px;
}

.section-row{
  padding-bottom: 30px;
  display: flex;
  flex-direction: row;
  width: 100%;
  background: linear-gradient(to left, #000428, #004592);
}

.section-column{
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: linear-gradient(to left, #000428, #004592);
}

#mision, #vision{
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
  padding: 20px;
}

#vision p{
  text-align: justify;
}

span {
  display: flex;
  justify-content: center;
  text-align: justify;
}

.titles{
  text-align: center;
  width: 100%;
  font-size: 2.5em;
  margin: 25px 0;
  font-family: 'Bebas Neue', cursive;
}

#remod {
  width: 140%;
}

.service{
  display: flex;
  flex-direction: column;
  width: 30%;
  justify-content: center;
  align-items: center;
  padding: 0 19px;
}

.service img{
  height: 100px;
}

.service span{
  padding-top: 15px;
  font-size: 1em;
}

.services{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-flow: row wrap;
}

#carousel-sections{
  display: flex;
  width: 100%;
  flex-flow: row wrap;
}

.carousel-sect{
  display: flex;
  flex-direction: column;
  width: 50% !important;
  justify-content: flex-start;
  align-items: center;
}

.carousel-root{
  padding: 0 20px;
}

#contact-us{
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}

.contact-person{
  display: flex;
  width: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-person h2{
  margin-bottom: 0 !important;
}

.phone{
  width: 35%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.phone i{
  width: 50%;
  display: flex;
}

.phone span{
  width: 50%;
  display: flex;
}

#mantra-div{
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
}

#mantra{
  font-size: 2em;
  color: aliceblue;
  font-weight: bold;
  background: lightseagreen;
  padding: 10px;
  display: none;
}

.service img{
  animation: heartbeat 3s ease-in-out infinite both;
}

.footer{
  background: #000000;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #434343, #000000);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #434343, #000000); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

h4{
  margin: 0;
}

.valores{
  padding: 20;
  position: "absolute";
  top: "50%";
  left: "50%";
  transform: "translate(-50%,-50%)";
}

.foot-tit{
  margin: 0;
}

.nav-button{
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99;
  background: white;
  border-radius: 30px;
  padding: 5px 7px;
  border: 0;
}

.menu-icon{
  font-size: 3em;
}

button:focus{
  outline: 0;
}

.navbar{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.title-nav{
  text-align: center;
}

.navbar-list{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.navbar-list a{
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 1.3em;
  cursor: pointer;
  color: black;
}
.navbar-list a:hover{
  background: cornflowerblue;
  color: white;
}


.header-nav{
  text-align: center;
  background: cornflowerblue;
  padding: 10px 0;
}

.list-mision{
  list-style-type: none;
  padding-inline-start: 0 !important;
}

#section1{
  padding-top: 20px;
  font-size: 48px;
  margin: 0;
}

a{
  text-decoration: none !important;
  color: white;
}

.header-nav h2{
  margin: 0;
  color: white;
}

.contact-form{
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.close{
  color: red;
}

.nav{
  z-index: 99;
  position: fixed;
  height: 100%;
  width: 150px;
  top: 0;
  background: #434343;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.item-nav{
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  word-wrap: break-word;
  overflow-x: hidden;
  transition: transform .2s;
}

.material-icons{
  font-size: 50px;
}

.item-nav p{
  margin: 0;
}

.nav > div{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
}

.material-icons:target{
  transform: scale(1.5);
}

.material-icons::after{
  transform: scale(1.5);
}

.item-nav:focus{
  font-size: 60px;
  transform: scale(1.6);
  transition-delay: 0.7s;
}

.rounded-profile{
  border-radius: 50%;
  width: 30%;
  margin-top: 15px;
}

.lista-proy{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.lista-proy > li{
  font-size: 1.5em;
}

.title-proy{
  display: flex;
  text-align: center;
  justify-content: center;
}

.carousel-modal{
  display: flex;
  flex-direction: column;
  width: 100% !important;
  justify-content: flex-start;
  align-items: center;
  color: black !important;
}

.mat-button-slide{
  display:inline-block;
  padding:0.3em 1.2em;
  margin:0 0.1em 0.1em 0;
  border:0.16em solid rgba(255,255,255,0);
  border-radius:2em;
  box-sizing: border-box;
  text-decoration:none;
  font-family:'Roboto',sans-serif;
  font-weight:300;
  color:#FFFFFF;
  text-shadow: 0 0.04em 0.04em rgba(0,0,0,0.35);
  text-align:center;
  transition: all 0.2s;
  background-color:#4e9af1;
  margin-left: 15px;
}

.mat-button-slide:hover{
  border-color: rgba(255,255,255,1);
  cursor: pointer;
}

.car-cover{
  background-size: cover;
}

.BrainhubCarousel .BrainhubCarousel__trackContainer{
  height: 500px !important;
}

.BrainhubCarousel .BrainhubCarousel__trackContainer .BrainhubCarousel__track{
  height: 100% !important;
}

.info-contact{
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.info-contact > span{
  margin: 10px;
}

@keyframes fadeImg {
  from {width: 100px; }
  to {width: 350px;}
}

/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: orange; }
}

@keyframes color-change {
  0% { color: rgb(147, 151, 202); }
  50% { color: rgb(110, 204, 255); }
  100% { color: rgb(147, 151, 202); }
}

@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}

@keyframes text-shadow-drop-center {
  0% {
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    text-shadow: 0 0 18px rgba(0, 0, 0, 0.35);
  }
}

@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}

@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
            transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
            transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}

@keyframes text-pop-up-br {
  0% {
    -webkit-transform: translateY(0) translateX(0);
            transform: translateY(0) translateX(0);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: none;
  }
  100% {
    -webkit-transform: translateY(50px) translateX(50px);
            transform: translateY(50px) translateX(50px);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: 0 1px 0 cadetblue, 0 2px 0 cadetblue, 0 3px 0 cadetblue, 0 4px 0 cadetblue, 0 5px 0 cadetblue, 0 6px 0 cadetblue, 0 7px 0 cadetblue, 0 8px 0 cadetblue, 0 9px 0 cadetblue, 0 50px 30px rgba(0, 0, 0, 0.3);
  }
}

@keyframes fadein {from { opacity: 0; }to  { opacity: 1; }}

@media only screen and (max-width : 768px) {
  html, body{
    padding: 0;
    margin: 0;
    border: 0;
    height: 100%;
  }

  .car-cover{
    width: 100%;
    height: 100%;
  }

  #section1 {
    margin: 0 !important;
  }

  .main-row{
    width: 100%;
    background: transparent;
    height: 300px;
  }

  .who{
    flex-direction: column;
    align-items: center;
  }

  .misvis-mob{
    flex-direction: column;
    align-items: center;
  }

  #mision, #vision{
    width: 85%;
  }

  #mision p, #vision p{
    text-align: justify;
  }

  .valores-mob{
    font-size: 1em;
  }

  .services{
    flex-flow: column wrap;
    align-items: center;
  }

  .service{
    width: 85%;
  }

  #carousel-sections{
    display: flex;
    width: 100%;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
  }

  .carousel-sect{
    width: 100% !important;
  }

  .carousel-sect h2{
    text-align: center;
  }

  .carousel-slider, .carousel{
    width: 100% !important;
  }

  .carousel-root{
    width: 98%;
    padding: 0 !important;
  }

  .direction-of{
    padding: 0 20px;
    text-align: justify;
  }

  #contact-us{
    flex-flow: column wrap;
  }

  .contact-person{
    width: 100%;
  }

  .phone{
    width: 100%;
  }

  .phone i{
    width: auto;
  }

  i {
    margin-right: 10px;
  }

  .group{
    width: 100%;
  }

  .BrainhubCarousel .BrainhubCarousel__trackContainer {
    height: auto !important;
  }

  .nav{
    position: fixed;
    top: auto;
    bottom: 0 !important;
    width: 100%;
    overflow: hidden;
    height: 70px;
    animation: none;
    animation-iteration-count: 0;
    font-size: 0.8em;
  }

  .nav > div{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
  }

  .material-icons{
    font-size: 40px;
    margin: 0;
  }

  .item-nav p{
    font-size: 0.8em;
  }

  .app-content{
    margin-left: 0;
    margin-bottom: 70px;
  }

  #remod{
    width: 100%;
  }

  .titles {
    margin: 0 !important;
    margin-top: 20px !important;
  }

  #img-who, #text-who{
    width: 100%;
    padding: 0;
  }

  .lista-proy{
    align-items: flex-start;
  }

  .react-responsive-modal-overlay{
    align-items: center !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .carousel .thumbs-wrapper{
    margin: 0 !important;
  }
}

@keyframes fixed {
  0%{bottom: 0};
  100%{bottom: 0}
}


/* MATERIAL INPUTS /*
/* form starting stylings ------------------------------- */
.form-mess{
  width: 80%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.group 			  { 
  position:relative; 
  margin-bottom:25px; 
  width: 60%;
}
input, textarea {
  background: transparent;
  font-size:18px;
  padding:10px 10px 10px 5px;
  display:block;
  width:300px;
  border:none;
  border-bottom:1px solid white;
  color: white;
  margin-top: 20px;
  width: 100%;
}
input:focus, textarea:focus { 
  outline:none; 
}

/* LABEL ======================================= */
label {
  color:white; 
  font-size:18px;
  font-weight:normal;
  position:absolute;
  pointer-events:none;
  left:5px;
  top:10px;
  transition:0.2s ease all; 
  -moz-transition:0.2s ease all; 
  -webkit-transition:0.2s ease all;
}

/* active state */
input:focus ~ label, input:valid ~ label, textarea:focus ~ label, textarea:valid ~ label 	{
  top:-5px;
  font-size:14px;
  color:white;
}

/* BOTTOM BARS ================================= */
.bar 	{ 
  position:relative; display:block; width:100% 
}

.bar:before, .bar:after 	{
  content:'';
  height:2px; 
  width:0;
  bottom:1px; 
  position:absolute;
  background:white; 
  transition:0.2s ease all; 
  -moz-transition:0.2s ease all; 
  -webkit-transition:0.2s ease all;
}
.bar:before {
  left:50%;
}
.bar:after {
  right:50%; 
}

/* active state */
input:focus ~ .bar:before, input:focus ~ .bar:after, textarea:focus ~ .bar:before, textarea:focus ~ .bar:after {
  width:50%;
}

/* HIGHLIGHTER ================================== */
.highlight {
  position:absolute;
  height:60%; 
  width:100px; 
  top:25%; 
  left:0;
  pointer-events:none;
  opacity:0.5;
}

.mat-button{
  height: 30px;
  width: 150px;
  font-size: 1em;
  color: white;
  background: deepskyblue;
  border: 0;
  border-radius: 4px;
  font-family: 'El Messiri', sans-serif;
  cursor: pointer;
}

/* active state */
input:focus ~ .highlight, textarea:focus ~ .highlight {
  -webkit-animation:inputHighlighter 0.3s ease;
  -moz-animation:inputHighlighter 0.3s ease;
  animation:inputHighlighter 0.3s ease;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(255,255,255, 0.5);
  font-size: 0.8em;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: rgb(255,255,255, 0.5);
  font-size: 0.8em;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: rgb(255,255,255, 0.5);
  font-size: 0.8em;
}

/* ANIMATIONS ================ */
@-webkit-keyframes inputHighlighter {
	from { background:white; }
  to 	{ width:0; background:transparent; }
}
@-moz-keyframes inputHighlighter {
	from { background:white; }
  to 	{ width:0; background:transparent; }
}
@keyframes inputHighlighter {
	from { background:white; }
  to 	{ width:0; background:transparent; }
}